import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import { checkForErrors } from "../utils/errors";

export const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(config);

export const addUserNameEmailOAuth = async (userAuth) => {
  if (!userAuth) return;
  const userRef = firebase.firestore().doc(`users/${userAuth.uid}`);
  try {
    const functions = firebase.functions();
    const checkAndUpdateAuthUserDocument = functions.httpsCallable(
      "checkAndUpdateAuthUserDocument"
    );

    const userAuthData = {
      displayName: userAuth.displayName,
      email: userAuth.email,
      lastSignInTime: userAuth.metadata.lastSignInTime,
      createdAt: userAuth.metadata.creationTime,
      sourceType: userAuth.providerData[0].providerId,
      source: "sign-in",
    };
    const resp = await checkAndUpdateAuthUserDocument(userAuthData);
    checkForErrors(resp.data);

    return userRef;
  } catch (err) {
    console.log("Error inside user update function", err.message);
    return userRef;
  }
};

export const auth = firebase.auth();
export const db = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();

export default firebase;
