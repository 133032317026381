import { lazy } from "react";
import GuardedRoute from "../components/guarded-routest/guarded-routest.component";

const Dashboard = lazy(() => import("../components/Dashboard"));
const UserLandingPage = lazy(() =>
  import("../pages/user-landing-page/user-landing-page.component")
);
const StripeCallbackHandler = lazy(() =>
  import("../pages/stripe-callback-handler/stripe-callback-handler.component")
);
const AddUsers = lazy(() => import("../components/addUsers"));
const CreatePaymentUrl = lazy(() =>
  import("../pages/create-payment-url/create-payment-url.component")
);
const SocialMedia = lazy(() => import("../components/socialMedia"));
const TenantConfiguration = lazy(() =>
  import("../pages/tenant-configuration/tenant-configuration.component")
);
const AuthenticateToken = lazy(() =>
  import("../pages/authenticate-token/authenticate-token.component")
);
const SignInUpComponent = lazy(() =>
  import("../pages/sign-in-up-component/sign-in-up-component")
);
const ConfigurePrompts = lazy(() =>
  import("../pages/prompts/configure-prompts")
);
const adminRoutes = [
  {
    path: "/admin-dashboard",
    element: <Dashboard />,
  },
  {
    path: "/addusers",
    element: <AddUsers />,
  },
  {
    path: "/create-checkout-sessions",
    element: <CreatePaymentUrl />,
  },
  {
    path: "/socialmedia",
    element: <SocialMedia />,
  },
  {
    path: "/tenant-configurations",
    element: <TenantConfiguration />,
  },
  {
    path: "/tenant-configurations/test",
    element: <TenantConfiguration />,
  },
  {
    path: "/prompts",
    element: <ConfigurePrompts />,
  },
];
const userRoutes = [
  {
    path: "/user-land",
    element: <UserLandingPage />,
  },
  {
    path: "/user-land/:id",
    element: <UserLandingPage />,
  },
  {
    path: "/stripe-callback",
    element: <StripeCallbackHandler />,
  },
  {
    path: "/stripe-callback/test",
    element: <StripeCallbackHandler />,
  },
];
const unAuthRoutes = [
  {
    path: "/sign-in",
    element: <SignInUpComponent />,
  },
  {
    path: "/login/authenticatedToken",
    element: <AuthenticateToken />,
  },
];

export const AllRoutes = [
  ...adminRoutes,
  ...userRoutes,
  ...unAuthRoutes,
  {
    elements: <GuardedRoute roles={["user"]} />,
    children: [...userRoutes],
  },
  {
    element: <GuardedRoute roles={["admin"]} />,
    children: [...adminRoutes],
  },
];
