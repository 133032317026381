import { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import Loader from "../components/loader/loader.component";
import Menu from "../components/sidebar/menu";
import { Outlet } from "react-router-dom";
import "./index.scss";
export const defaultRedirect = (currentUser, navigate) => {
  if (!currentUser.authenticated) {
    navigate("/sign-in", { replace: true });
  } else {
    // redirect based on role
    if (currentUser.role === "admin") {
      navigate("/admin-dashboard", { replace: true });
    } else {
      console.log("it commming here ?");
      navigate("/user-land", { replace: true });
    }
  }
};

const IndexPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.base);

  useEffect(() => {
    if (location.pathname === "/") {
      defaultRedirect(user, navigate);
    }
  }, [user.role, user.authenticated]);

  return (
    <Suspense fallback={<Loader zIndex={9999} />}>
      <div className="App" id="App">
        <div className="main-app-container">
          {user.authenticated && <Menu />}
          <Outlet />
        </div>
      </div>
    </Suspense>
  );
};

export default IndexPage;
