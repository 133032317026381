export const checkForErrors = (response) => {
  if (!response.success) {
    const errorObject = {
      status: 400,
      message: response.message
        ? response.message
        : response.error
        ? response.error
        : "",
    };
    throw errorObject;
  }
};
