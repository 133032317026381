import firebase, { db } from "../../firebase/firebase.utils";

const vibrantColors = [
  "#FF0000",
  "#FFA500",
  "#DDA0DD",
  "#00FF00",
  "#00FFFF",
  "#0000FF",
  "#800080",
  "#FF00FF",
  "#FF1493",
  "#8B4513",
  "#FF4500",
  "#FFD700",
  "#32CD32",
  "#00CED1",
  "#0000CD",
  "#9400D3",
  "#BA55D3",
  "#FF69B4",
  "#008000",
  "#008080",
  "#000080",
  "#800000",
  "#C71585",
  "#FF8C00",
  "#B8860B",
  "#FFB6C1",
  "#FFDAB9",
  "#7FFFD4",
  "#98FB98",
  "#AFEEEE",
  "#ADD8E6",
  "#FFFF00",
];

export const changeUser = (user) => ({
  type: "CHANGE_USER",
  payload: user,
});

export const setFileList = (filelist) => ({
  type: "SET_FILE_LIST",
  payload: filelist,
});

export const setFileToUpload = (file) => ({
  type: "SET_FILE_TO_UPLOAD",
  payload: file,
});
export const uploadFileComplete = (url) => ({
  type: "UPLOAD_FILE_COMPLETE",
  payload: url,
});

export const uploadFileError = () => ({
  type: "UPLOAD_FILE_ERROR",
});

export const setAyrShareProfiles = (profiles) => ({
  type: "SET_AYRSHARE_PROFILES",
  payload: profiles,
});

export const setAyrShareJWTURL = (url) => ({
  type: "SET_AYRSHARE_JWT_URL",
  payload: url,
});
export const setDonations = (donations) => ({
  type: "SET_DONATIONS",
  payload: donations,
});
export const setAllowedEmails = (emails) => ({
  type: "SET_ALLOWED_EMAILS",
  payload: emails,
});

export const setEmailStats = (emailStats) => ({
  type: "SET_EMAILS_STATS",
  payload: emailStats,
});

//SET_UNIQUE_DONOR_STATS

export const setUniqueDonorStats = (stats) => ({
  type: "SET_UNIQUE_DONOR_STATS",
  payload: stats,
});

export const setEmailStatsByTenant = (stats) => ({
  type: "SET_EMAIL_STATS_BY_TENANT",
  payload: stats,
});

export const setMontlyDonationStatsByTenant = (stats) => ({
  type: "SET_MONTHLY_DONATAION_STATS_BY_TENANT",
  payload: stats,
});

export const setUniqueDonorStatsByTenant = (stats) => ({
  type: "SET_UNIQUE_DONOR_STATS_BY_TENANT",
  payload: stats,
});

export const signInWithGoogle = () => async (dispatch) => {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({ prompt: "select_account" });
  firebase.auth().signInWithPopup(provider);

  //dispatch(changeUser("FQ"));
};

export const addUserEmail = (emailAddress) => async (dispatch) => {
  const addUserRef = db.collection("allowedEmails").doc(emailAddress);
  await addUserRef.set({
    allowed: true,
  });
};

export const getEmailStats =
  () =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    console.log("Get Email Stats");
    const firebase = getFirebase();
    const getEmailsTatsQueryFunction = firebase
      .functions()
      .httpsCallable("getEmailCounts");
    const result = await getEmailsTatsQueryFunction();
    console.log("result from bigquery", result.data);
    if (result.data.status === "success") {
      dispatch(setEmailStats(result.data.results));
    }
  };

export const updateUserUploadLoading = (data) => ({
  type: "UPDATE_USER_UPLOAD_LOADING",
  payload: data,
});

export const getAyrShareProfiles = () => async (dispatch) => {
  const getEmailsTatsQueryFunction = firebase
    .functions()
    .httpsCallable("getProfilesFromAyrShareOnCall");
  try {
    const ayrShareResponse = await getEmailsTatsQueryFunction();
    console.log("ayrShareResponse", ayrShareResponse.data.profiles.profiles);
    dispatch(setAyrShareProfiles(ayrShareResponse.data.profiles.profiles));
  } catch (err) {
    console.log("Error", err);
  }
};

export const getAyrShareProfileJWT = (title) => async (dispatch) => {
  const GetJWTFromAyrShare_title_callable = firebase
    .functions()
    .httpsCallable("GetJWTFromAyrShare_title_callable");
  try {
    console.log("title", title);
    const ayrShareResponse = await GetJWTFromAyrShare_title_callable(title);
    console.log("ayrShareResponse", ayrShareResponse);
    dispatch(setAyrShareJWTURL(ayrShareResponse.data.ayrshareReponse.url));
  } catch (err) {
    console.log("Error", err);
  }
};

export const getNumberOfDonorStats = () => async (dispatch) => {
  const getEmailsTatsQueryFunction = firebase
    .functions()
    .httpsCallable("getNumOfDonorsOverTime");
  const result = await getEmailsTatsQueryFunction();
  //console.log('result from bigquery', result.data.results);
  dispatch(setUniqueDonorStats(result.data.results));
};
export const getNumberOfDonorStatsByTenant = () => async (dispatch) => {
  const getEmailsTatsQueryFunctionByTenant = firebase
    .functions()
    .httpsCallable("getNumOfDonorsOverTimeByTenantDetails");
  const result = await getEmailsTatsQueryFunctionByTenant();

  const allDates = result.data.dates;
  var initialData = result.data;
  console.log("initialData", initialData);
  const initialResults = initialData.results;
  var newDataSet = [];
  for (const i in initialResults) {
    const record = initialResults[i];
    const newData = record.data.map((d) => d[0]);
    const newRecord = {
      data: newData,
      label: record.label,
      borderColor: vibrantColors[i],
    };
    newDataSet.push(newRecord);
  }

  const newData = {
    labels: allDates,
    datasets: newDataSet,
  };
  console.log("newData", newData);
  dispatch(setUniqueDonorStatsByTenant(newData));
};
export const getNumberOfEmailStatsByTenant = () => async (dispatch) => {
  const getEmailsTatsQueryFunctionByTenant = firebase
    .functions()
    .httpsCallable("getNumOfEmailsSentPerDayByTenant");
  const result = await getEmailsTatsQueryFunctionByTenant();

  const allDates = result.data.dates;
  var initialData = result.data;
  console.log("initial Emails Data", initialData);
  const initialResults = initialData.results;
  var newDataSet = [];
  for (const i in initialResults) {
    const record = initialResults[i];
    const newData = record.data;
    const newRecord = {
      data: newData,
      label: record.label,
      backgroundColor: vibrantColors[i],
    };
    newDataSet.push(newRecord);
  }

  const newData = {
    labels: allDates,
    datasets: newDataSet,
  };
  console.log("new Email Data", newData);
  dispatch(setEmailStatsByTenant(newData));
};

export const getMonthlyStatsByTenant = () => async (dispatch) => {
  console.log("Get Monthly Stats By Tenant");
  const getMonthylStatsnByTenant = firebase
    .functions()
    .httpsCallable("getMonthlyDonationStats");
  var result;
  try {
    result = await getMonthylStatsnByTenant();
  } catch (err) {
    console.log("Error getting monthly stats", err);
    return;
  }

  const allDates = result.data.dates;
  var initialData = result.data;
  console.log("initial Emails Data", initialData);
  const initialResults = initialData.results;
  var newDataSet = [];
  for (const i in initialResults) {
    const record = initialResults[i];
    const newData = record.data;
    const newRecord = {
      data: newData,
      label: record.label,
      backgroundColor: vibrantColors[i],
    };
    newDataSet.push(newRecord);
  }

  const newData = {
    labels: allDates,
    datasets: newDataSet,
  };
  console.log("new Monthly Stats Data", newData);
  dispatch(setMontlyDonationStatsByTenant(newData));
};

export const getAllFilesForUser = () => async (dispatch, getState) => {
  const userId = getState().base.user.uid;

  try {
    const filesRef = firebase.storage().ref().child(`users/${userId}/`);

    const allFiles = await filesRef.listAll();

    const files = allFiles.items;
    const promises = files.map((fileRef) => fileRef.getDownloadURL());
    Promise.all(promises).then((urls) => {
      dispatch(setFileList(urls));
    });
  } catch (err) {
    console.log("Error gettings all files for user", err);
  }
};

export const uploadFile = () => async (dispatch, getState) => {
  const fileToUpload = getState().base.fileToUpload;
  const userId = getState().base.user.uid;
  if (fileToUpload) {
    const storageRef = firebase
      .storage()
      .ref(`users/${userId}/${fileToUpload.name}`);
    try {
      const snapshot = await storageRef.put(fileToUpload);
      const downloadURL = await snapshot.ref.getDownloadURL();
      console.log("File uploaded:", downloadURL);
      dispatch(uploadFileComplete(downloadURL));
    } catch (e) {
      console.error("Error uploading file:", e);
      dispatch(uploadFileError());
    }
  } else {
    console.log("No File Selected to upload");
  }
};

//        var querySnapshot = await db.collection("donations").get();.
export const getAllDonations = () => async (dispatch) => {
  console.log("Listenting to All Donations");
  try {
    //        var querySnapshot = await db.collection("donations").get();.

    db.collection("donations").onSnapshot(
      (snapshot) => {
        const updatedDonations = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch(setDonations(updatedDonations));
      },
      (error) => {
        console.log("Error getting collection info", error);
        // dispatch(signOut());
      }
    );
  } catch (e) {
    console.log("Error getting collection info");
    console.log(e);
  }
};

export const getAllAllowedEmails = () => async (dispatch) => {
  console.log("Listenting to All Allowed Emails");
  try {
    //        var querySnapshot = await db.collection("donations").get();.

    db.collection("allowedEmails").onSnapshot(
      (snapshot) => {
        const allowedEmails = snapshot.docs.map((doc) => ({
          id: doc.id,
        }));
        dispatch(setAllowedEmails(allowedEmails));
      },
      (error) => {
        console.log("Error getting collection info", error);
        // dispatch(signOut());
      }
    );
  } catch (e) {
    console.log("Error getting collection info");
    console.log(e);
  }
};

export const signOut = () => async (dispatch) => {
  console.log("Signing Out");
  try {
    firebase.auth().signOut();
    window.location.replace("/");
  } catch (e) {
    console.log("Error Signing out");
    console.log(e);
  }
};
